import { Pipe, PipeTransform } from '@angular/core';
import { LANGUAGE } from '@app/core/services/language.service';

@Pipe({ name: 'formatDecimal' })
export class FormatDecimalPipe implements PipeTransform {
  transform(
    value: number | undefined | null,
    language: LANGUAGE = 'de',
  ): string {
    return value ? new Intl.NumberFormat(language).format(value) : '0';
  }
}
