import { Component } from '@angular/core';
import { Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CreateDeliveryAddressModalComponent } from '@modules/cart/create-delivery-address-modal/create-delivery-address-modal.component';
import { ModalComponent } from '../../../shared/components/modal/modal.component';
import { MatFormField, MatError } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { NgIf } from '@angular/common';
import { CountriesSelectComponent } from '../../../shared/components/packex-select/countries-select.component';
import { ButtonComponent } from '../../../shared/components/ui/button/button.component';
import { TranslocoPipe } from '@jsverse/transloco';

@Component({
    selector: 'packex-create-billing-address-modal',
    templateUrl: './create-billing-address-modal.component.html',
    styleUrls: ['./create-billing-address-modal.component.scss'],
    imports: [ModalComponent, FormsModule, ReactiveFormsModule, MatFormField, MatInput, NgIf, MatError, CountriesSelectComponent, ButtonComponent, TranslocoPipe]
})
export class CreateBillingAddressModalComponent extends CreateDeliveryAddressModalComponent {
  override createForm() {
    super.createForm();

    this.form.controls['vatId'].addValidators(Validators.required);
    this.form.updateValueAndValidity();
  }
}
