import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { VariantsService } from '@app/core/services/variants.service';
import { ModalComponent } from '@shared/components/modal/modal.component';
import { ProductOption, REWORK } from '@shared/models/product-option';
import { Variant } from '@shared/models/variant';
import { ProductOptionService } from '@shared/services/product-option.service';
import { ModalComponent as ModalComponent_1 } from '../../../../shared/components/modal/modal.component';
import { NgIf, NgFor } from '@angular/common';
import { WellNotificationComponent } from '../../../../shared/components/well-notification/well-notification.component';
import { MatCheckbox } from '@angular/material/checkbox';
import { TooltipComponent } from '../../../../shared/components/tooltip/tooltip.component';
import { ButtonComponent } from '../../../../shared/components/ui/button/button.component';
import { MatTooltip } from '@angular/material/tooltip';
import { FormatPricePipe } from '@shared/pipes/format-price.pipe';
import { TranslocoPipe } from '@jsverse/transloco';

@Component({
    selector: 'packex-variant-approve-dialog',
    templateUrl: './variant-approve-error-dialog.component.html',
    styleUrls: ['./variant-approve-error-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None,
    imports: [ModalComponent_1, NgIf, FormsModule, ReactiveFormsModule, NgFor, WellNotificationComponent, MatCheckbox, TooltipComponent, ButtonComponent, MatTooltip, FormatPricePipe, TranslocoPipe]
})
export class VariantApproveErrorDialogComponent
  extends ModalComponent
  implements OnInit
{
  variant!: Variant;
  productOptions: ProductOption[] = [];

  form = new FormGroup({
    po_checked: new FormControl(false, [Validators.requiredTrue]),
    notice_accepted: new FormControl(false),
  });

  PRODUCT_OPTION_SKU = REWORK;

  showNoticeCheckbox = false;

  loading = false;

  constructor(
    dialogRef: MatDialogRef<VariantApproveErrorDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public dialogData: {
      variant: Variant;
    },
    public readonly variantsService: VariantsService,
    private readonly productOptionsService: ProductOptionService,
  ) {
    super(dialogRef);
  }

  ngOnInit() {
    this.variantsService
      .getVariant(this.dialogData.variant.id)
      .subscribe((variant) => {
        this.variant = variant;

        const po_checked = variant.availableProductOptions.find(
          (po) => po.sku === this.PRODUCT_OPTION_SKU && po.preselected,
        );
        this.form.get('po_checked')?.patchValue(Boolean(po_checked));
      });

    this.productOptionsService
      .load()
      .subscribe((productOptions) => (this.productOptions = productOptions));
  }

  get formIsValid() {
    return this.form.valid;
  }

  public accept(): void {
    if (this.loading) return;

    if (!this.variant || !this.formIsValid || !this.productOption) return;

    this.loading = true;

    const productOptions = this.form.controls.po_checked.value
      ? [this.productOption]
      : [];

    this.variantsService
      .addOneOffProductOptionsIds(
        this.variant.id,
        productOptions.map((x) => x.id),
      )
      .subscribe({
        next: () => {
          this.loading = false;
          this.onConfirm();
        },
        error: () => (this.loading = false),
      });
  }

  public get title() {
    return 'APPROVE_VARIANT.ERROR_TITLE';
  }

  public get description(): string {
    if (this.errors.length && this.warnings.length) {
      return 'APPROVE_VARIANT.ERROR_WARNING_DESCRIPTION';
    }
    return 'APPROVE_VARIANT.ERROR_DESCRIPTION';
  }

  public get notification() {
    return 'APPROVE_VARIANT.ERROR_NOTIFICATION';
  }

  public get tooltip() {
    return 'APPROVE_VARIANT.ERROR_TOOLTIP';
  }

  public get errors(): string[] {
    return this.variant.lastPreflightResult.errors;
  }

  public get warnings(): string[] {
    return this.variant.lastPreflightResult.warnings;
  }

  public get infos(): string[] {
    return this.variant.lastPreflightResult.infos;
  }

  public get productOption(): ProductOption | null {
    return (
      this.productOptions.find((po) => po.sku === this.PRODUCT_OPTION_SKU) ||
      null
    );
  }
}
