<form [formGroup]="form">
    <div class="mt-4 w-100">
        <div class="subtitle-2 text-primary-medium mb-3">{{ 'CART.DELIVERY.TITLE' | transloco }}</div>

        <mat-radio-group aria-label="Select an option" formControlName="delivery_method">
            <mat-radio-button
                    [value]="deliveryMethod.id"
                    class="me-3 mb-3" *ngFor="let deliveryMethod of deliveryMethods"
                    color="primary"
                    [attr.data-testid]="'cart-modal-deliveryMethod-'+ deliveryMethod.name"
                    [ngClass]="{ disabled: !deliveryMethod.enabled}"
                    [disabled]="!deliveryMethod.enabled"
                    [matTooltip]="deliveryMethod.enabled ? '' : 'CONFIGURATION.SHIPPING.UNAVAILABLE' | transloco"
            >
                <span class="subtitle-2" [ngClass]="{'text-grey-medium': disabled}">{{ deliveryMethod.name }}</span>
                <span class="caption text-primary-medium">
          {{ 'CONFIGURATION.SHIPPING.ESTIMATED_PRODUCTION_DAYS' | transloco :{days: getDeliveryMethod()?.estimatedProductionDays} }}
                    +
                    {{ 'CONFIGURATION.SHIPPING.ESTIMATED_DELIVERY_DAYS' | transloco :  {days: getDeliveryMethod()?.estimatedShippingDays} }}
                    ({{ deliveryMethod.country.isoCode.toUpperCase() }})
        </span>
            </mat-radio-button>
        </mat-radio-group>
    </div>
</form>
