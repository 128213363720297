import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { InvitationService } from '@app/core/services/invitation.service';
import { NotificationService } from '@app/core/services/notification.service';
import { UserService } from '@app/core/services/user.service';
import { CartDeliveryAddressCardComponent } from '@modules/cart/cart-delivery-address-card/cart-delivery-address-card.component';
import { CartService } from '@modules/cart/cart.service';
import { ChooseBillingAddressModalComponent } from '@modules/cart/choose-address-modal/choose-billing-address-modal/choose-billing-address-modal.component';
import { CreateBillingAddressModalComponent } from '@modules/cart/create-billing-address-modal/create-billing-address-modal.component';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Address } from '@shared/models/address';
import validator from 'validator';
import { NgIf, AsyncPipe } from '@angular/common';
import { CartAddressCardComponent } from '../cart-address-card/cart-address-card.component';
import { TranslocoPipe } from '@jsverse/transloco';

@UntilDestroy()
@Component({
    selector: 'packex-cart-billing-address-card',
    templateUrl: './cart-billing-address-card.component.html',
    styleUrls: ['./cart-billing-address-card.component.scss'],
    imports: [NgIf, CartAddressCardComponent, AsyncPipe, TranslocoPipe]
})
export class CartBillingAddressCardComponent extends CartDeliveryAddressCardComponent {
  constructor(
    dialog: MatDialog,
    cartService: CartService,
    userService: UserService,
    notificationService: NotificationService,
    invitationService: InvitationService,
  ) {
    super(
      dialog,
      cartService,
      userService,
      notificationService,
      invitationService,
    );
  }

  public override getNotificationEmails(invitationEmails: string[]) {
    this.notificationService
      .getNotificationEmails(
        this.notificationService.filterForBillingEmails,
        invitationEmails,
      )
      .subscribe((emails) => (this.emails = emails));
  }

  get address() {
    const defaultBillingAddress =
      this.userService.user?.organization.billingAddress;
    const cartBillingAddress = this.cartService.cartValue.billingAddress;
    return cartBillingAddress || defaultBillingAddress || null;
  }

  public override edit(): void {
    this.dialog
      .open(ChooseBillingAddressModalComponent, {
        data: {
          current: this.address,
        },
      })
      .afterClosed()
      .subscribe((address) => {
        if (address) {
          this.onCreate.emit(address);
        }
      });
  }

  public override create(): void {
    this.dialog
      .open(CreateBillingAddressModalComponent)
      .afterClosed()
      .subscribe((address: Address) => {
        if (address) {
          this.onCreate.emit(address);
        }
      });
  }

  public override save(recipients: string[]): void {
    const invoiceRecipientEmails = recipients.filter((email) =>
      validator.isEmail(email),
    );

    this.onSave.emit({
      billingAddress: this.cartService.cartValue.billingAddress,
      invoiceRecipientEmails: invoiceRecipientEmails,
    });
  }
}
