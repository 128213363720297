import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { InvitationService } from '@app/core/services/invitation.service';
import { NotificationService } from '@app/core/services/notification.service';
import { UserService } from '@app/core/services/user.service';
import { CartService } from '@modules/cart/cart.service';
import { ChooseDeliveryAddressModalComponent } from '@modules/cart/choose-address-modal/choose-delivery-address-modal/choose-delivery-address-modal.component';
import { CreateDeliveryAddressModalComponent } from '@modules/cart/create-delivery-address-modal/create-delivery-address-modal.component';
import { Address } from '@shared/models/address';
import { Cart } from '@shared/models/cart';
import { Observable } from 'rxjs';
import validator from 'validator';
import { NgIf, AsyncPipe } from '@angular/common';
import { CartAddressCardComponent } from '../cart-address-card/cart-address-card.component';
import { MatCheckbox } from '@angular/material/checkbox';
import { FormsModule } from '@angular/forms';
import { TooltipComponent } from '../../../shared/components/tooltip/tooltip.component';
import { MatSlideToggle } from '@angular/material/slide-toggle';
import { TranslocoPipe } from '@jsverse/transloco';

@Component({
    selector: 'packex-cart-delivery-address-card',
    templateUrl: './cart-delivery-address-card.component.html',
    styleUrls: ['./cart-delivery-address-card.component.scss'],
    imports: [NgIf, CartAddressCardComponent, MatCheckbox, FormsModule, TooltipComponent, MatSlideToggle, AsyncPipe, TranslocoPipe]
})
export class CartDeliveryAddressCardComponent implements OnInit {
  @Output() onSave = new EventEmitter();
  @Output() onCreate = new EventEmitter();

  public equalsBillingAddress = false;
  public showMissingAddressError = false;
  public emails: string[] = [];

  constructor(
    public dialog: MatDialog,
    public readonly cartService: CartService,
    public readonly userService: UserService,
    public readonly notificationService: NotificationService,
    public readonly invitationService: InvitationService,
  ) {}

  ngOnInit() {
    this.cart.subscribe((cart) => {
      this.equalsBillingAddress =
        cart.billingAddress?.id === cart.deliveryAddress?.id;
    });

    this.getInvitationEmails();
  }

  private getInvitationEmails() {
    this.invitationService.load().subscribe((invitations) => {
      const invitationEmails = invitations.map(
        (invitation) => invitation.email,
      );

      this.getNotificationEmails(invitationEmails);
    });
  }

  public getNotificationEmails(invitationEmails: string[]) {
    this.notificationService
      .getNotificationEmails(
        this.notificationService.filterForDeliveryEmails,
        invitationEmails,
      )
      .subscribe((emails) => (this.emails = emails));
  }

  get cart(): Observable<Cart> {
    return this.cartService.cart;
  }

  public edit(): void {
    this.dialog
      .open(ChooseDeliveryAddressModalComponent, {
        data: {
          current: this.cartService.cartValue.deliveryAddress,
        },
      })
      .afterClosed()
      .subscribe((address) => {
        if (address) {
          this.onCreate.emit(address);
        }
      });
  }

  public create(): void {
    this.dialog
      .open(CreateDeliveryAddressModalComponent)
      .afterClosed()
      .subscribe((address: Address) => {
        if (address) {
          this.onCreate.emit(address);
        }
      });
  }

  public save(recipients: string[]): void {
    this.showMissingAddressError = false;

    const deliveryAddress = this.equalsBillingAddress
      ? this.cartService.cartValue.billingAddress
      : this.cartService.cartValue.deliveryAddress;

    if (!deliveryAddress) {
      this.showMissingAddressError = true;
    }

    this.onSave.emit({
      deliveryAddress,
      statusUpdateEmails: recipients.filter((email) =>
        validator.isEmail(email),
      ),
    });
  }

  public onWhitelabelShippingChanged(): void {
    this.cartService.update({
      whiteLabelShipping: this.cartService.cartValue.whiteLabelShipping,
    });
  }

  public onChangeEqualBillingAddress(isEqual: boolean) {
    this.equalsBillingAddress = isEqual;

    const billingAddress = this.cartService.cartValue.billingAddress;
    const deliveryAddress = isEqual
      ? billingAddress
      : this.cartService.cartValue.deliveryAddress;

    this.cartService.update(
      {
        billingAddress,
        deliveryAddress,
        statusUpdateEmails: this.cartService.cartValue.statusUpdateEmails || [],
        invoiceRecipientEmails:
          this.cartService.cartValue.invoiceRecipientEmails,
        step: this.cartService.cartValue.step,
      },
      true,
    );
  }
}
