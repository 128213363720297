import { Component, ViewEncapsulation } from '@angular/core';
import {
  PackexSelectComponent,
  SelectOption,
} from '@shared/components/packex-select/packex-select.component';
import { ModalCartItem } from '@shared/models/cart';
import { TranslocoDirective, TranslocoPipe } from '@jsverse/transloco';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { NgIf, NgFor, NgClass, AsyncPipe } from '@angular/common';
import { MatSelect, MatSelectTrigger } from '@angular/material/select';
import { IconComponent } from '../icon/icon.component';
import { MatInput } from '@angular/material/input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatOption } from '@angular/material/core';
import { ConditionalPipe } from '../../pipes/conditional.pipe';

export type ModalCartItemSelectOption = ModalCartItem & SelectOption;

@Component({
    selector: 'packex-modal-cart-item-select',
    templateUrl: './packex-select.component.html',
    styleUrls: ['./packex-select.component.scss'],
    encapsulation: ViewEncapsulation.None,
    imports: [TranslocoDirective, MatFormField, NgIf, MatLabel, MatSelect, MatSelectTrigger, IconComponent, MatInput, FormsModule, ReactiveFormsModule, MatOption, NgFor, NgClass, AsyncPipe, TranslocoPipe, ConditionalPipe]
})
export class ModalCartItemSelectComponent extends PackexSelectComponent<ModalCartItemSelectOption> {}
