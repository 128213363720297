<div [class]="['statusContainer', status()?.currentStatus?.id]">
  <div>
    <img
      ngSrc="/assets/images/rewards.svg"
      alt="Rewards Logo"
      class="rewards-logo"
      height="28"
      width="28"
    />
  </div>
  <div class="rewardsDetails">
    <div class="firstLine">
      <div class="title">{{ 'LOYALTY.MORE_POINTS_COLLECTED' | transloco }}</div>
      <div class="rewardPoints">
        +{{ additionalPoints() | formatDecimal }}
        {{ 'LOYALTY.POINTS' | transloco }}
      </div>
    </div>
    <div class="pointsContainer">
      <div class="currentPoints">
        <span class="title">{{ 'LOYALTY.UPCOMING_POINTS' | transloco }}</span
        ><br />
        {{ totalPoints | formatDecimal }}
        {{ 'LOYALTY.POINTS' | transloco }}
      </div>
    </div>
    <div class="hint">
      <packex-icon icon="info_circle" color="primary"></packex-icon>
      {{ 'LOYALTY.DEFERRED_CREDIT' | transloco }}
    </div>
    <packex-button
      type="secondary"
      color="primary-dark"
      (buttonClick)="goToLoyaltyClub()"
      class="w-auto mt-2"
      >{{ 'LOYALTY.CLUBPAGE_LINK_TITLE' | transloco }}</packex-button
    >
  </div>
</div>
