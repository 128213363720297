import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { CartService } from '@modules/cart/cart.service';
import { ChooseAddressModalComponent } from '@modules/cart/choose-address-modal/choose-address-modal.component';
import { billingAddressConfig } from '@shared/address-config';
import { ModalComponent } from '@shared/components/modal/modal.component';
import { SnackBarService } from '@shared/components/snackbar/snackbar.service';
import { Address } from '@shared/models/address';
import { AddressService } from '@shared/services/address.service';
import { map } from 'rxjs';
import { ModalComponent as ModalComponent_1 } from '../../../../shared/components/modal/modal.component';
import { AddressChooseListComponent } from '../../../../shared/components/address-choose-list/address-choose-list.component';
import { TranslocoPipe } from '@jsverse/transloco';

@Component({
    selector: 'packex-choose-billing-address-modal',
    templateUrl: '../choose-address-modal.component.html',
    styleUrls: ['../choose-address-modal.component.scss'],
    encapsulation: ViewEncapsulation.None,
    imports: [ModalComponent_1, AddressChooseListComponent, TranslocoPipe]
})
export class ChooseBillingAddressModalComponent
  extends ChooseAddressModalComponent
  implements OnInit
{
  constructor(
    dialogRef: MatDialogRef<ModalComponent>,
    addressService: AddressService,
    dialog: MatDialog,
    cartService: CartService,
    snackbarService: SnackBarService,
    @Inject(MAT_DIALOG_DATA)
    dialogData: {
      current: Address;
    },
  ) {
    super(
      dialogRef,
      addressService,
      dialog,
      cartService,
      snackbarService,
      dialogData,
    );
  }

  override ngOnInit(): void {
    this.setConfig(billingAddressConfig);
    super.ngOnInit();
  }

  public override loadAddresses(): void {
    this.addressService
      .load()
      .pipe(
        map((addresses) =>
          addresses.filter((address: Address) => address.validBillingAddress),
        ),
      )
      .subscribe((addresses) => (this.addresses = addresses));
  }

  public override chooseAddress(address: Address): void {
    this.onConfirm(address);
  }
}
