import { Component, input } from '@angular/core';
import { NgStyle } from '@angular/common';

@Component({
  selector: 'packex-progress-bar',
  styleUrl: './progress-bar.component.scss',
  templateUrl: './progress-bar.component.html',
  imports: [NgStyle],
})
export class ProgressBarComponent {
  value = input.required<number>();
  pendingValue = input<number|null>(null)
}
