import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  QueryList,
  ViewChildren,
  ViewEncapsulation,
} from '@angular/core';
import { AbstractControl, FormArray, FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { fieldHasError } from '@app/utils/formHelper';
import { validateEmail } from '@app/utils/validators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { isEqual } from 'lodash';
import { debounceTime, distinctUntilChanged, Subscription } from 'rxjs';
import { NgFor, NgClass, NgIf } from '@angular/common';
import { MatFormField, MatError } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { ButtonComponent } from '../../../shared/components/ui/button/button.component';
import { TranslocoPipe } from '@jsverse/transloco';

@UntilDestroy()
@Component({
    selector: 'packex-recipients',
    templateUrl: './recipients.component.html',
    styleUrls: ['./recipients.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    imports: [FormsModule, ReactiveFormsModule, NgFor, MatFormField, MatInput, NgClass, NgIf, MatError, ButtonComponent, TranslocoPipe]
})
export class RecipientsComponent implements OnChanges, OnInit {
  form = new FormGroup({
    recipients: new FormArray([]),
  });

  @Input() placeholder!: string;
  @Input() initialRecipients: string[] = [];
  @Output() recipientsChanged = new EventEmitter<string[]>();
  @ViewChildren('inputElement') inputElements?: QueryList<ElementRef>;

  subscription = new Subscription();

  ngOnInit() {
    this.addRecipient();
    this.subscribeFormValueChanges();
  }

  ngOnChanges() {
    if (this.subscription) this.subscription.unsubscribe();

    if (!isEqual(this.recipients.value, this.initialRecipients)) {
      this.recipients.clear();

      if (this.initialRecipients.length) {
        this.initialRecipients.forEach((recipient) =>
          this.addRecipient(recipient),
        );
      } else {
        this.addRecipient();
      }
    }

    this.form.markAsTouched();
    this.subscribeFormValueChanges();
  }

  get recipients() {
    return this.form.get('recipients') as FormArray;
  }

  public addRecipient(value = '', focus = false): void {
    this.recipients.push(new FormControl(value, validateEmail));

    if (focus) {
      this.focusLastElement();
    }
  }

  public removeRecipient(i: number): void {
    this.recipients.removeAt(i);
    this.onChangeRecipients();
  }

  public fieldHasError(formField: AbstractControl | null): boolean {
    return fieldHasError(formField);
  }

  public onKeyDown(event: KeyboardEvent, index: number): void {
    if (event.key === 'Enter') {
      const recipient = this.recipients.at(index);
      recipient.markAsDirty();
      recipient.updateValueAndValidity();

      if (recipient.valid && !recipient.pristine) {
        this.addRecipient('', true);
      }
    }
  }

  private subscribeFormValueChanges(): void {
    this.subscription = this.form.valueChanges
      .pipe(untilDestroyed(this), distinctUntilChanged(), debounceTime(50))
      .subscribe(() => {
        if (this.form.valid && this.form.touched && this.form.dirty) {
          this.onChangeRecipients();
        }
      });
  }

  public focusLastElement(): void {
    // sets timeout to wait for angular rendering the new field created
    setTimeout(() => {
      if (this.inputElements?.length) {
        this.inputElements.last.nativeElement.focus();
      }
    }, 1);
  }

  private onChangeRecipients(): void {
    this.recipientsChanged.emit(this.recipients.value);
  }
}
