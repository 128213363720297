import { translate } from '@jsverse/transloco';
import { User } from '@shared/models/user';
import { Variant, VariantStatus } from '@shared/models/variant';

export const variantHasStatus = (
  variant: Variant,
  variantStatus: VariantStatus,
): boolean => {
  return variant.status === variantStatus;
};

export const variantHasWarning = (variant: Variant): boolean => {
  return ['pending_user_approval'].includes(variant.status);
};

export const variantInProgress = (variant: Variant): boolean => {
  return [
    'pending_artwork',
    'pending_preflight',
    'queued_for_preflight',
  ].includes(variant.status);
};

export const variantHasError = (variant: Variant): boolean => {
  return ['preflight_error', 'invalid'].includes(variant.status);
};

export const getVariantName = (variant: Variant): string => {
  return variant.blankArtwork
    ? variant.name
      ? variant.name
      : translate('VARIANT.BLANK')
    : variant.name;
};

export const getAuthorFromVariant = (variant: Variant): Partial<User> => {
  return variant.lastChangedBy
    ? variant.lastChangedBy
    : { firstName: 'Unknown', lastName: 'User' };
};

export const variantIsOrderable = (variant: Variant): boolean => {
  return variant.orderable && !variant.pendingServiceFromPreviousOrder;
};

export const getTooltipForNotOrderableVariant = (variant: Variant): string => {
  if (variant.orderable) return '';

  if (variant.pendingServiceFromPreviousOrder) {
    return translate('VARIANT.HAS_PENDING_SERVICE_FROM_PREVIOUS_ORDER');
  }

  return translate('VARIANT.ADJUST_PRINT_DATA_TO_ORDER');
};
