import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { getVariantName } from '@app/utils/variant-helper';
import { ModalCartService } from '@modules/cart/modal-cart.service';
import { translate, TranslocoPipe } from '@jsverse/transloco';
import { UntilDestroy } from '@ngneat/until-destroy';
import { ModalCartItem } from '@shared/models/cart';
import {
  BRAILLE,
  FSC,
  PREPRESS_CHECK,
  ProductOption,
  REWORK,
} from '@shared/models/product-option';
import { NgClass, NgFor, NgIf } from '@angular/common';
import { DownloadButtonComponent } from '@shared/components/ui/download-button/download-button.component';
import { MatFormField } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { ButtonComponent } from '@shared/components/ui/button/button.component';
import { IconComponent } from '@shared/components/icon/icon.component';
import { MatTooltip } from '@angular/material/tooltip';
import { MatCheckbox } from '@angular/material/checkbox';
import { TooltipComponent } from '@shared/components/tooltip/tooltip.component';
import { MatChipOption } from '@angular/material/chips';
import { WellNotificationComponent } from '@shared/components/well-notification/well-notification.component';
import { FormatPricePipe } from '@shared/pipes/format-price.pipe';

export const defaultAmount = 1000;

@UntilDestroy()
@Component({
  selector: 'packex-modal-cart-item',
  templateUrl: './modal-cart-item.component.html',
  styleUrls: ['./modal-cart-item.component.scss'],
  encapsulation: ViewEncapsulation.None,
  imports: [
    NgClass,
    FormsModule,
    ReactiveFormsModule,
    NgIf,
    DownloadButtonComponent,
    MatFormField,
    MatInput,
    ButtonComponent,
    IconComponent,
    NgFor,
    MatTooltip,
    MatCheckbox,
    TooltipComponent,
    MatChipOption,
    WellNotificationComponent,
    FormatPricePipe,
    TranslocoPipe,
  ],
})
export class ModalCartItemComponent implements OnInit, OnChanges {
  @Input() index?: number;
  @Input() item!: ModalCartItem;
  @Input() loading!: boolean;

  @Output() itemRemoved = new EventEmitter();

  form = new FormGroup<Record<string, AbstractControl>>({});

  productOptionsForm = new FormGroup<Record<string, AbstractControl>>({});
  availableProductOptions: ProductOption[] = [];

  protected readonly getVariantName = getVariantName;

  constructor(private readonly modalCartService: ModalCartService) {}

  ngOnChanges(changes: SimpleChanges) {
    const loading = changes['loading'];
    const amountForm = this.form.get('amount');

    if (
      loading &&
      loading.currentValue &&
      amountForm?.value !== 0 &&
      !amountForm?.disabled
    ) {
      this.form.get('amount')?.disable();
    } else if (amountForm?.disabled) {
      this.form.get('amount')?.enable();
    }

    if (loading && loading.currentValue) {
      Object.keys(this.productOptionsForm.controls).forEach((k) => {
        this.productOptionsForm.get(k)?.disable();
      });
    }
  }

  ngOnInit(): void {
    this.availableProductOptions = [];

    this.form.addControl(
      'amount',
      new FormControl(this.item.configuration.amount, [
        Validators.min(this.minAmount),
        Validators.max(this.maxAmount),
      ]),
    );

    if (this.item.availableProductOptions) {
      this.item.availableProductOptions
        .sort((a, b) => (b.mandatory ? 1 : 0) - (a.mandatory ? 1 : 0))
        .forEach((service) => {
          const selected =
            this.item.productOptions?.find((_) => _.id === service.id) !==
            undefined;

          // #PPE-688 && PPE-1034 only shows braille if it's present in inventoryItem
          if (
            [BRAILLE, FSC].includes(service.sku) &&
            !this.item.inventoryItem.productOptions.find(
              (x) => x.sku === service.sku,
            )
          ) {
            return;
          } else {
            this.addProductOption(service.sku, {
              ...service,
              preselected: selected,
            });
          }
        });
    }

    this.productOptionsForm.valueChanges.subscribe((values) => {
      this.item.productOptions = [];

      this.item.availableProductOptions.forEach((po: ProductOption) => {
        if (po.mandatory || values[po.sku]) {
          this.item.productOptions?.push(po);
        }
      });

      this.modalCartService.updateItem(this.item);
    });
  }

  private addProductOption(sku: string, productOption: ProductOption): void {
    this.availableProductOptions = [
      ...this.availableProductOptions,
      productOption,
    ];
    this.productOptionsForm.addControl(
      sku,
      new FormControl({
        value: productOption.preselected,
        disabled:
          productOption?.mandatory ||
          !productOption?.enabled ||
          this.loading ||
          false,
      }),
    );
  }

  public removeItem(): void {
    this.itemRemoved.emit();
    this.modalCartService.removeItemFromCart(this.item);
  }

  public onKeyPress($event: KeyboardEvent): void {
    if ($event.key === 'Enter') {
      this.updateItem(this.form.value['amount']);
    }
  }

  private updateItem(value: number) {
    this.item.configuration.amount = value || 0;

    if (this.item.configuration.amount >= 0) {
      this.modalCartService.updateItem(this.item);
    }
  }

  public getTooltipByProductOption(productOption: ProductOption) {
    switch (productOption.sku) {
      case BRAILLE:
        return 'DISABLED_PRODUCT_OPTIONS.TOOLTIP_BRAILLE';
      case FSC:
        return 'DISABLED_PRODUCT_OPTIONS.TOOLTIP_FSC';
      case PREPRESS_CHECK:
        return 'DISABLED_PRODUCT_OPTIONS.TOOLTIP_PREPRESS_CHECK';
      case REWORK:
        return 'DISABLED_PRODUCT_OPTIONS.TOOLTIP_REWORK';
      default:
        return '';
    }
  }

  get minAmount(): number {
    return this.item.inventoryItem.construction.minimumConfiguration.amount;
  }

  get maxAmount(): number {
    return this.item.inventoryItem.construction.maximumConfiguration.amount;
  }

  public hasError(field: string, error: string): boolean {
    const errors = this.form.get(field)?.errors;
    return errors ? errors[error] : false;
  }

  public getConfigurationErrorField(
    error: ModalCartItem['configurationError'],
  ): string {
    if (error?.fields && error?.fields.length > 0) {
      return `INVENTORY.FIELDS.${error?.fields[0].field}`;
    } else {
      return 'INVENTORY.FIELDS.general';
    }
  }

  public getItemWellNotification(): string {
    const productOptions =
      this.item?.productOptions?.map((po) => po.name) || [];

    return translate('PRODUCT_OPTIONS.ADDITIONAL_PRODUCTION_DAYS', {
      services: productOptions.join(', '),
      numberServices: productOptions.length,
      additionalProductionDays: this.item.additionalProductionDays,
    });
  }

  onBlur() {
    const amount = this.form.get('amount')?.value || 0;

    this.updateItem(amount);
  }
}
