<div class="py-3 border-bottom border-grey-medium" *ngIf="!cart.voucher">
  <form class="d-flex voucher-form">
    <mat-form-field appearance="outline" class="voucher-form-field me-2">
      <input
        type="text"
        matInput
        [formControl]="voucherCodeControl"
        [placeholder]="'CART.VOUCHER.CODE' | transloco"
      />
      <mat-error *ngIf="voucherCodeControl.hasError('invalid')">{{
        'CART.VOUCHER.INVALID' | transloco
      }}</mat-error>
    </mat-form-field>
    <packex-button
      (buttonClick)="redeemVoucher()"
      class="w-auto"
      type="secondary"
      buttonPadding="py-1"
      [disabled]="voucherCodeControl.invalid"
      >{{ 'CART.VOUCHER.REDEEM' | transloco }}
    </packex-button>
  </form>
</div>
<div
  class="py-3 border-bottom border-grey-medium d-flex align-items-center"
  *ngIf="cart.voucher"
>
  <div class="bg-grey-light subtitle-2 p-3 w-100">
    {{ cart.voucher.code }}
  </div>
  <packex-button
    type="clear-icon"
    icon="close"
    class="ms-3"
    (buttonClick)="removeVoucher()"
  ></packex-button>
</div>
@if (cart.voucher || cart.loyaltyDiscount > 0 || cart.redeemedLoyaltyDiscount >
0) {
<div
  *ngIf="
    cart.voucher || cart.loyaltyDiscount > 0 || cart.redeemedLoyaltyDiscount > 0
  "
  class="py-2 border-bottom border-grey-medium"
>
  <div class="d-flex justify-content-between mb-1">
    <p class="text-primary-medium">
      {{ 'CART.VOUCHER.PREVIOUS_TOTAL' | transloco }}
    </p>
    <p class="text-primary-medium">
      {{ cart.netPriceWithoutVoucher | formatPrice }}
    </p>
  </div>
  @if (cart.voucher) {
  <div class="d-flex justify-content-between">
    <p class="text-primary-medium subtitle-2">
      {{ 'CART.VOUCHER.CODE' | transloco }}
      <span *ngIf="cart.voucher && cart.voucher?.type === 'percentage'"
        >({{ cart.voucher.discount * 100 }}%)</span
      >
    </p>
    <p class="text-primary-medium subtitle-2">
      {{ -cart.netDiscountValue | formatPrice }}
    </p>
  </div>
  } @if (cart.loyaltyDiscount) {
  <div class="d-flex justify-content-between mb-1">
    <p class="text-primary-medium subtitle-2">
      {{ 'CART.LOYALTY_DISCOUNT' | transloco }}
    </p>
    <p class="text-primary-medium">{{ -cart.loyaltyDiscount | formatPrice }}</p>
  </div>
  } @if (cart.redeemedLoyaltyDiscount) {
  <div class="d-flex justify-content-between mb-1">
    <p class="text-primary-medium subtitle-2">
      {{ 'CART.LOYALTY_SERVICES' | transloco }}
    </p>
    <p class="text-primary-medium">
      {{ -cart.redeemedLoyaltyDiscount | formatPrice }}
    </p>
  </div>
  }
</div>
}
