import { translate } from '@jsverse/transloco';
import { ProductOption } from '@shared/models/product-option';

export const additionalProductOptionsCheckingDays = (
  productOptions: ProductOption[],
): string => {
  return translate('PRODUCT_OPTIONS.ADDITIONAL_SERVICE_CHECK_DAYS', {
    days: Math.round(
      productOptions.reduce(
        (acc, productOption) => acc + productOption.extraProductionDays,
        0,
      ),
    ),
  });
};
