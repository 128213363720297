import { Component, Inject, OnInit } from '@angular/core';
import {
  FormControl,
  FormGroup,
  Validators,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { formattedFormat } from '@app/utils/configuration.helper';
import { CartService } from '@modules/cart/cart.service';
import { ConfirmDialogComponent } from '@shared/components/confirm-dialog/confirm-dialog.component';
import { ModalComponent } from '@shared/components/modal/modal.component';
import { SnackBarService } from '@shared/components/snackbar/snackbar.service';
import { CartItem } from '@shared/models/cart';
import { Configuration } from '@shared/models/configuration';
import { LABEL, SHIPPING_BOX } from '@shared/models/construction';
import { InventoryItem } from '@shared/models/inventory-item';
import { ReferenceCode } from '@shared/models/referenceCode';
import { isEqual, orderBy } from 'lodash';
import { ConfigurationAttributeValue } from '@shared/models/configuration-attribute-value';
import { ModalComponent as ModalComponent_1 } from '../../../shared/components/modal/modal.component';
import { NgIf } from '@angular/common';
import { ButtonComponent } from '../../../shared/components/ui/button/button.component';
import { MatFormField, MatHint } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { TranslocoPipe } from '@jsverse/transloco';

@Component({
  selector: 'packex-cart-references',
  templateUrl: './cart-references.component.html',
  styleUrls: ['./cart-references.component.scss'],
  imports: [
    ModalComponent_1,
    NgIf,
    ButtonComponent,
    FormsModule,
    ReactiveFormsModule,
    MatFormField,
    MatInput,
    MatHint,
    TranslocoPipe,
  ],
})
export class CartReferencesComponent extends ModalComponent implements OnInit {
  MAX_CHAR_LENGTH = 32;

  form: FormGroup = new FormGroup({
    customerOrderReference: new FormControl('', [
      Validators.max(this.MAX_CHAR_LENGTH),
    ]),
    customerArticleNumber: new FormControl('', [
      Validators.max(this.MAX_CHAR_LENGTH),
    ]),
    customerArticleDescription: new FormControl('', [
      Validators.max(this.MAX_CHAR_LENGTH),
    ]),
    customerComment: new FormControl(''),
  });

  currentItemIndex = 0;

  cartItems: CartItem[] = [];

  constructor(
    dialogRef: MatDialogRef<CartReferencesComponent>,
    private readonly dialog: MatDialog,
    private readonly cartService: CartService,
    private readonly snackbarService: SnackBarService,
    @Inject(MAT_DIALOG_DATA)
    public dialogData: {
      item?: CartItem;
    },
  ) {
    super(dialogRef);
  }

  ngOnInit(): void {
    this.cartItems = orderBy(
      this.cartService.cartValue.items,
      'sortingPosition',
      'desc',
    );

    if (this.dialogData.item) {
      this.currentItemIndex = this.cartItems.findIndex(
        (item) => item.id === this.dialogData.item?.id,
      );
    }

    this.load();
  }

  get showGrammage(): boolean {
    return ![LABEL, SHIPPING_BOX].includes(
      this.item.variant.inventoryItem.construction.category,
    );
  }

  private save() {
    const value = this.form.value;

    this.cartItems = this.cartItems.map((x) => {
      return isEqual(x.id, this.item.id)
        ? {
            ...x,
            customerOrderReference: value.customerOrderReference,
            customerArticleNumber: value.customerArticleNumber,
            customerArticleDescription: value.customerArticleDescription,
            customerComment: value.customerComment,
          }
        : x;
    });
  }

  private load() {
    this.form.controls['customerOrderReference'].patchValue(
      this.item.customerOrderReference,
    );
    this.form.controls['customerArticleNumber'].patchValue(
      this.item.customerArticleNumber,
    );
    this.form.controls['customerArticleDescription'].patchValue(
      this.item.customerArticleDescription,
    );
    this.form.controls['customerComment'].patchValue(this.item.customerComment);
  }

  get item(): CartItem {
    return this.cartItems[this.currentItemIndex];
  }

  get nextIndex() {
    return this.currentItemIndex + 1;
  }

  get previousIndex() {
    return this.currentItemIndex - 1;
  }

  get nextDisabled(): boolean {
    return this.nextIndex >= this.cartItems.length;
  }

  get previousDisabled(): boolean {
    return this.previousIndex < 0;
  }

  public next(): void {
    if (!this.nextDisabled) {
      this.setIndex(this.nextIndex);
    }
  }

  public previous(): void {
    if (!this.previousDisabled) {
      this.setIndex(this.previousIndex);
    }
  }

  public override onConfirm() {
    this.save();

    if (!this.form.pristine) {
      const referenceCodes: ReferenceCode[] = this.cartItems.map(
        (cartItem: CartItem) => ({
          customerOrderReference: cartItem.customerOrderReference,
          customerArticleNumber: cartItem.customerArticleNumber,
          customerArticleDescription: cartItem.customerArticleDescription,
          customerComment: cartItem.customerComment,
          orderItemIDs: [cartItem.id],
        }),
      );

      const obs = this.cartService.update(
        {
          referenceCodes,
        },
        true,
      );

      if (obs) {
        obs.subscribe({
          error: (e) => {
            this.snackbarService.showSimpleError(
              'CART.REFERENCE_INFORMATIONS.SAVE_ERROR',
            );
            return e;
          },
        });
      }
    }

    super.onConfirm();
  }

  public override dismiss() {
    if (this.form.pristine) {
      return super.dismiss();
    }

    this.dialog
      .open(ConfirmDialogComponent, {
        data: {
          title: 'CART.REFERENCE_INFORMATIONS.CONFIRM_DIALOG.TITLE',
          content: 'CART.REFERENCE_INFORMATIONS.CONFIRM_DIALOG.DESCRIPTION',
          primaryButtonText:
            'CART.REFERENCE_INFORMATIONS.CONFIRM_DIALOG.BUTTON',
        },
      })
      .afterClosed()
      .subscribe((response) => {
        if (response) {
          super.dismiss();
        }
      });
  }

  public getProductOptions(item: CartItem): string {
    return item.productOptions.map((po) => po.name).join(', ');
  }

  private setIndex(index: number) {
    this.save();
    this.currentItemIndex = index;
    this.load();
  }

  public getFormattedFormat(entity: Configuration | InventoryItem): string {
    return formattedFormat(entity);
  }

  public getCoating(): ConfigurationAttributeValue | undefined {
    return this.item.configuration.configurationAttributeValues[0];
  }
}
