import { Component, Input } from '@angular/core';
import { formattedFormat } from '@app/utils/configuration.helper';
import { CartProduct } from '@shared/models/cart';
import { LABEL, SHIPPING_BOX } from '@shared/models/construction';
import { InventoryItem } from '@shared/models/inventory-item';
import { ConfigurationAttributeValue } from '@shared/models/configuration-attribute-value';
import { NgClass, NgIf } from '@angular/common';
import { TranslocoPipe } from '@jsverse/transloco';

@Component({
    selector: 'packex-inventory-item-header',
    templateUrl: './inventory-item-header.component.html',
    imports: [NgClass, NgIf, TranslocoPipe]
})
export class InventoryItemHeaderComponent {
  @Input() inventoryItem!: InventoryItem;
  @Input() cartProduct!: CartProduct;
  @Input() reducedView = false;

  get showGrammage(): boolean {
    return ![LABEL, SHIPPING_BOX].includes(
      this.inventoryItem.construction.category,
    );
  }

  public getFormattedFormat(inventoryItem: InventoryItem) {
    return formattedFormat(inventoryItem);
  }

  public getCoating(): ConfigurationAttributeValue | undefined {
    return (
      this.cartProduct?.items[0]?.configuration
        .configurationAttributeValues[0] ||
      (this.inventoryItem?.configurationAttributeValues
        ? this.inventoryItem?.configurationAttributeValues[0]
        : undefined)
    );
  }
}
