import { Component, OnInit } from '@angular/core';
import { groupCartItems } from '@app/utils/cart.helper';
import { CartService } from '@modules/cart/cart.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Cart, CartProduct } from '@shared/models/cart';
import { BehaviorSubject, map } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'packex-cart-items',
  template: '',
  providers: [CartService],
})
export class CartItemsComponent implements OnInit {
  cartProducts$ = new BehaviorSubject<CartProduct[]>([]);

  constructor(private readonly cartService: CartService) {}

  ngOnInit() {
    this.cartService.cart$
      .pipe(
        untilDestroyed(this),
        map((cart) => groupCartItems(cart.items)),
      )
      .subscribe((cartProducts: CartProduct[]) => {
        this.cartProducts$.next(cartProducts);
      });
  }

  get cart$(): BehaviorSubject<Cart> {
    return this.cartService.cart$;
  }

  get cartServiceInstance(): CartService {
    return this.cartService;
  }

  get deliveryCountry(): string {
    return this.cartService.deliveryCountry;
  }
}
