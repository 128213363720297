import { Component, inject, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { ROUTES } from '@app/routes';
import { cartEmptyScreenConfig } from '@modules/cart/cart-empty-screen';
import { CartService } from '@modules/cart/cart.service';
import {
  EmptyScreenComponent,
  EmptyScreenConfig,
} from '@shared/components/empty-screen/empty-screen.component';
import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { CartProductComponent } from '../cart-product/cart-product.component';
import { CartItemsComponent } from '@modules/cart/cart-items/cart-items.component';

@Component({
  selector: 'packex-cart-item-list',
  templateUrl: './cart-item-list.component.html',
  styleUrls: ['./cart-item-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
  imports: [NgIf, EmptyScreenComponent, NgFor, CartProductComponent, AsyncPipe],
  providers: [CartService],
})
export class CartItemListComponent extends CartItemsComponent {
  emptyScreenConfig: EmptyScreenConfig = cartEmptyScreenConfig;
  private router = inject(Router);

  public async goToCatalogue() {
    await this.router.navigateByUrl(`/${ROUTES.CONSTRUCTIONS}`);
  }
}
