import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ApiService } from '@app/core/services/api.service';
import { CartModalComponent } from '@modules/cart/cart-modal/cart-modal.component';
import {
  CartItem,
  initialModalCart,
  ModalCart,
  ModalCartItem,
} from '@shared/models/cart';
import { InventoryItem } from '@shared/models/inventory-item';
import { ProductOption } from '@shared/models/product-option';
import { Quote } from '@shared/models/quote';
import { Variant } from '@shared/models/variant';
import { isEqual } from 'lodash';
import {
  BehaviorSubject,
  distinctUntilChanged,
  filter,
  Observable,
  of,
  switchMap,
} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ModalCartService {
  private cart$ = new BehaviorSubject<ModalCart>(initialModalCart);

  constructor(
    private readonly dialog: MatDialog,
    private readonly apiService: ApiService,
  ) {
    this.getQuoteForCart();
  }

  /**
   * Retrieves a quote for the current cart by calling the API endpoint.
   * If there are no items in the cart or no delivery method is selected, a warning is logged and the method returns.
   * The method also updates the isLoading$ and cart$ observables.
   *
   * @return {void}
   */
  private getQuoteForCart() {
    const mapModalCartForCompare = (cart: ModalCart) => ({
      deliveryMethodId: cart.deliveryMethodId,
      items: cart.items.map((item: ModalCartItem) => ({
        productOptions: item.productOptions,
        amount: item.configuration.amount,
      })),
      loaded: cart.loaded,
    });

    this.cart$
      .pipe(
        filter((cart: ModalCart) => !!cart.deliveryMethodId),
        distinctUntilChanged((before, after) => {
          return isEqual(
            mapModalCartForCompare(before),
            mapModalCartForCompare(after),
          );
        }),
        switchMap((cart: ModalCart) => {
          return cart.items.length
            ? this.getQuoteForItems(this.cart.items, this.cart.deliveryMethodId)
            : of(null);
        }),
      )
      .subscribe((quote) => {
        this.patchCart({
          loading: false,
          loaded: true,
          items: quote
            ? ModalCartService.mapCartItemsToModalCartItems(quote.items)
            : [],
        });
      });
  }

  get cart(): ModalCart {
    return this.cart$.getValue();
  }

  public getCartAsObservable(): Observable<ModalCart> {
    return this.cart$.asObservable();
  }

  public resetCart(): void {
    this.cart$.next(initialModalCart);
  }

  public addVariantToCartAndOpenCartModal(
    inventoryItem: InventoryItem,
    items: ModalCartItem[] = [],
  ): void {
    this.resetCart();

    this.dialog.open(CartModalComponent, {
      data: {
        inventoryItem,
        items,
        useDefaultOptions: true,
      },
    });
  }

  /**
   * Adds items to the cart, initializes the default product options and updates the quote.
   *
   * @param {ModalCartItem[]} modalCartItems - The items to be added to the cart.
   * @param useDefaultOptions
   * @param {boolean} replace - Optional. Specifies whether existing items of the same variant should be replaced. Default is false.
   * @returns {void}
   */
  public addItemsToCart(
    modalCartItems: ModalCartItem[],
    useDefaultOptions: boolean,
    replace = false,
  ): void {
    let cartItems = this.cart$.getValue().items;

    // Remove existing items of the same variant from cart if replace is true
    if (replace) {
      cartItems = cartItems.filter(
        (item) => !modalCartItems.map((_) => _.id).includes(item.id),
      );
    } else {
      modalCartItems = modalCartItems.filter(
        (item) => !cartItems.map((_) => _.id).includes(item.id),
      );
    }

    modalCartItems = modalCartItems.map((item: ModalCartItem) => ({
      ...item,
      productOptions: useDefaultOptions
        ? item.availableProductOptions?.filter(
            (_) =>
              _.preselected ||
              _.mandatory ||
              item.productOptions?.find((option) => option.id === _.id) ||
              false,
          ) || []
        : item.productOptions,
    }));

    // Add items to cart on top of existing items
    const items = [...cartItems, ...modalCartItems];

    this.patchCart({
      loading: !!items.length,
      loaded: false,
      items,
    });
  }

  /**
   * Removes an item from the cart and updates the quote.
   * @param {ModalCartItem} modalCartItem - The item to be removed from the cart.
   */
  public removeItemFromCart(modalCartItem: ModalCartItem): void {
    const items =
      this.cart.items.filter((item: Variant) => item.id !== modalCartItem.id) ||
      [];

    this.patchCart({
      loading: !!items.length,
      loaded: false,
      items,
    });
  }

  public getQuoteForItems(
    items: ModalCartItem[],
    deliveryMethodId: string,
  ): Observable<Quote> {
    this.setLoading(true);

    const body = {
      variants: [
        ...items.map((item) => ({
          variantId: item.id,
          amount: item.configuration.amount,
          productOptionIds: [
            ...new Set(
              item.productOptions?.map(
                (productOption: ProductOption) => productOption.id,
              ) || [],
            ),
          ],
        })),
      ],
      deliveryMethodId,
    };
    return this.apiService.post(`cart/quote`, body);
  }

  public updateItem(updateItem: ModalCartItem): void {
    const items = this.cart$.getValue().items;

    this.patchCart({
      loading: true,
      loaded: false,
      items: items.map((item: ModalCartItem) =>
        item.id === updateItem.id ? updateItem : item,
      ),
    });
  }

  static mapCartItemsToModalCartItems(items: CartItem[]): ModalCartItem[] {
    return items.map((item) => ({
      ...item,
      ...item.variant,
    }));
  }

  public setDeliveryMethodId(deliveryMethodId: string) {
    if (deliveryMethodId && deliveryMethodId !== this.cart.deliveryMethodId) {
      this.patchCart({
        deliveryMethodId,
        loading: !!this.cart.items.length,
        loaded: false,
      });
    }
  }

  public setLoading(loading: boolean) {
    this.patchCart({ loading });
  }

  private patchCart(value: Partial<ModalCart>) {
    this.cart$.next({
      ...this.cart$.getValue(),
      ...value,
    });
  }
}
