import { AfterViewInit, Component, Input, ViewChild } from '@angular/core';
import { MatExpansionPanel, MatExpansionPanelHeader, MatExpansionPanelTitle } from '@angular/material/expansion';
import { BehaviorSubject } from 'rxjs';

@Component({
    selector: 'packex-accordion-step',
    templateUrl: './accordion-step.component.html',
    imports: [MatExpansionPanel, MatExpansionPanelHeader, MatExpansionPanelTitle]
})
export class AccordionStepComponent implements AfterViewInit {
  @ViewChild(MatExpansionPanel) expansionPanel?: MatExpansionPanel;

  @Input() expanded = false;
  public openChanged = new BehaviorSubject<boolean>(false);

  ngAfterViewInit() {
    this.expansionPanel?.afterExpand.subscribe(() => {
      this.open();
    });

    this.expansionPanel?.afterCollapse.subscribe(() => {
      this.close();
    });
  }

  public open(): void {
    this.expanded = true;
    this.openChanged.next(this.expanded);
  }

  public close(): void {
    this.expanded = false;
    this.openChanged.next(this.expanded);
  }
}
