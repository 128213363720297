import { Component, Input, ViewEncapsulation, OnInit } from '@angular/core';
import { mapDeliveryCountryToSelectOption } from '@app/utils/configuration.helper';
import {
  PackexSelectComponent,
  SelectOption,
} from '@shared/components/packex-select/packex-select.component';
import { DeliveryCountry } from '@shared/models/delivery-country';
import { TranslocoDirective, TranslocoPipe } from '@jsverse/transloco';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { NgIf, NgFor, NgClass, AsyncPipe } from '@angular/common';
import { MatSelect, MatSelectTrigger } from '@angular/material/select';
import { IconComponent } from '../icon/icon.component';
import { MatInput } from '@angular/material/input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatOption } from '@angular/material/core';
import { ConditionalPipe } from '../../pipes/conditional.pipe';

export type CountrySelectOption = DeliveryCountry & SelectOption;

@Component({
  selector: 'packex-countries-select',
  templateUrl: './packex-select.component.html',
  styleUrls: ['./packex-select.component.scss'],
  encapsulation: ViewEncapsulation.None,
  imports: [
    TranslocoDirective,
    MatFormField,
    NgIf,
    MatLabel,
    MatSelect,
    MatSelectTrigger,
    IconComponent,
    MatInput,
    FormsModule,
    ReactiveFormsModule,
    MatOption,
    NgFor,
    NgClass,
    AsyncPipe,
    TranslocoPipe,
    ConditionalPipe,
  ],
})
export class CountriesSelectComponent
  extends PackexSelectComponent<CountrySelectOption>
  implements OnInit
{
  override ngOnInit() {
    super.ngOnInit();
    this.translatesValues = false;
  }

  @Input()
  override set options(options: CountrySelectOption[]) {
    this.options$.next(
      options.map(
        (option: CountrySelectOption) =>
          mapDeliveryCountryToSelectOption(option) as CountrySelectOption,
      ),
    );
  }

  override get current(): CountrySelectOption | undefined | null {
    return this._current.getValue();
  }

  @Input()
  override set current(value: CountrySelectOption | null) {
    if (value) {
      value = mapDeliveryCountryToSelectOption(value);
      super.current = value;
    }
  }
}
