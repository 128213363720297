<div class="progress-container">
  @if(pendingValue()) {
  <div
    class="pending-progress-bar"
    [ngStyle]="{
           'width.%': (pendingValue()!) * 100,
         }"
  ></div>
  }
  <div
    class="progress-bar"
    [ngStyle]="{
         'width.%': value() * 100,
       }"
  ></div>
</div>
