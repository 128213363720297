import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CartService } from '@modules/cart/cart.service';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { TranslocoPipe } from '@jsverse/transloco';

@Component({
    selector: 'packex-payment-processing',
    templateUrl: './payment-processing.component.html',
    styleUrls: ['./payment-processing.component.scss'],
    imports: [MatProgressSpinner, TranslocoPipe]
})
export class PaymentProcessingComponent implements OnInit {
  public paymentId!: string;

  constructor(
    private router: Router,
    private readonly route: ActivatedRoute,
    private readonly cartService: CartService,
  ) {}

  ngOnInit() {
    this.route.params.subscribe(({ paymentId }) => {
      this.paymentId = paymentId;

      this.loadPayment();
    });
  }

  loadPayment() {
    // Load payment details
    this.cartService
      .processPayment(this.paymentId)
      .subscribe(async (response) => {
        await this.router.navigateByUrl(`/cart/order-received/${response.id}`);
      });
  }
}
