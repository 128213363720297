import {
  AfterViewInit,
  Component,
  ContentChildren,
  QueryList,
  ViewEncapsulation,
} from '@angular/core';
import { AccordionStepComponent } from '@shared/components/accordion/accordion-step/accordion-step.component';
import { MatAccordion } from '@angular/material/expansion';

@Component({
    selector: 'packex-accordion',
    templateUrl: './accordion.component.html',
    styleUrls: ['./accordion.component.scss'],
    encapsulation: ViewEncapsulation.None,
    imports: [MatAccordion]
})
export class AccordionComponent implements AfterViewInit {
  @ContentChildren(AccordionStepComponent)
  accordionSteps?: QueryList<AccordionStepComponent>;

  ngAfterViewInit(): void {
    const steps = this.accordionSteps?.toArray();

    steps?.forEach((x, index) => {
      x.openChanged.subscribe((expanded) => {
        if (expanded) {
          this.closeAllOtherSteps(index);
        }
      });
    });
  }

  private closeAllOtherSteps(index: number) {
    const steps = this.accordionSteps
      ?.toArray()
      .filter((x, stepIndex) => stepIndex !== index);

    steps?.forEach((step) => {
      step.close();
    });
  }
}
